import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './Clientlogo.css';
import { Pagination, Autoplay } from 'swiper/modules';

const ClientLogo = () => {
    return (
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide><img src="assets/img/about/clientlogo/logo1.jpg" alt='Client logo 1'/></SwiperSlide>
          <SwiperSlide><img src="assets/img/about/clientlogo/logo2.png" alt='Client logo 2'/></SwiperSlide>
          <SwiperSlide><img src="assets/img/about/clientlogo/logo3.png" alt='Client logo 3'/></SwiperSlide>
          <SwiperSlide><img src="assets/img/about/clientlogo/logo4.png" alt='Client logo 4'/></SwiperSlide>
          <SwiperSlide><img src="assets/img/about/clientlogo/logo5.png" alt='Client logo 5'/></SwiperSlide>
          <SwiperSlide><img src="assets/img/about/clientlogo/logo6.png" alt='Client logo 6'/></SwiperSlide>
          <SwiperSlide><img src="assets/img/about/clientlogo/logo7.png" alt='Client logo 7'/></SwiperSlide>
          <SwiperSlide><img src="assets/img/about/clientlogo/logo8.png" alt='Client logo 8'/></SwiperSlide>
          <SwiperSlide><img src="assets/img/about/clientlogo/logo9.png" alt='Client logo 9'/></SwiperSlide>
        </Swiper>
    );
}

export default ClientLogo;
    