export const bannerData = {
  title: "Key Benefits of Google Ads",
  features: [
    "Immediate Visibility",
    "Targeted Advertising",
    "Budget Control",
    "Pay-per-Click (PPC)",
    "Keyword Targeting",
    "Adaptability",
    "Wide Reach",
    "Local Advertising",
    "Remarketing",
    "Ad Extensions",
    " ROI Tracking",
    ," Ad Scheduling",
    "Competitive Research",
    "Data-Driven Insights"
  ]
};


const GoogleAdsList=[
    {
       title: "Search Ads",
       des: "We create targeted search ads that appear in Google search results, driving relevant traffic to your website and increasing visibility.",
       img: "assets/img/service-icon/1.png",
     },
     {
       title: "Display Ads",
       des: "Our visually appealing display ads reach potential customers across the Google Display Network, enhancing brand awareness and engagement.",
       img: "assets/img/service-icon/2.png",
     },
     {
       title: "Video Ads",
       des: "Leverage YouTube and Google Video Partners with our engaging video ads, capturing attention and driving action from viewers.",
       img: "assets/img/service-icon/3.png",
     },
     {
      title: "Shopping Ads",
      des: "Showcase your products directly in Google search results with shopping ads, designed to attract shoppers and increase sales.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Remarketing Campaigns",
      des: "Reconnect with previous visitors through remarketing campaigns, reminding them of your products and encouraging conversions.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Keyword Research and Optimization",
      des: "We conduct thorough keyword research and optimize your ads to ensure they reach the right audience at the right time.",
      img: "assets/img/service-icon/3.png",
    },
    {
      title: "Ad Copy and Design",
      des: "Craft compelling ad copy and eye-catching designs that resonate with your target audience and encourage clicks.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Bid Management",
      des: "Our experts manage your ad bids strategically to maximize your budget and ensure optimal ad placement.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Performance Tracking and Reporting",
      des: "Monitor your ad campaigns with detailed performance tracking and reporting, providing insights for continuous optimization.",
      img: "assets/img/service-icon/3.png",
    },
    {
      title: "Conversion Tracking",
      des: "Implement conversion tracking to measure the effectiveness of your ads, helping you understand and improve your ROI.",
      img: "assets/img/service-icon/3.png",
    },
    ]
  
export default GoogleAdsList;