import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import ContactAreaOne from "./ContactAreaOne";
import ContactForm from "./ContactForm";

const BannerFive = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== BannerFive Start ==================*/}
      <div
        className='banner-area bg-relative banner-area-2 pb-0 bg-cover'
        style={{ backgroundImage: 'url("./assets/img/banner-5/5.png")' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 pb-xl-5 align-self-center'>
              <div className='banner-inner pe-xl-4 pb-5'>
                <h6
                  className='text-black subtitle'//bg-base-2
                  data-aos='fade-right'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                >
                  AASMO Digital
                </h6>
                <h2
                  className='title text-white'
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
                 The Ultimate One-Step Solution for All Your Marketing Needs                
                 </h2>
                 <div
                  className='center pe-xl-4'
                  data-aos='fade-right'
                  data-aos-delay='350'
                  data-aos-duration='1500'
                >
                <div className="banner-services ">
                <img src="assets/img/banner-5/youtube.png"/>
                <p>Youtube Add</p>
                </div>
                
                <div className="banner-services">
                <img src="assets/img/banner-5/google.jpg"/>
                <p>Google Add</p>
                </div>
                
                <div className="banner-services">
                <img src="assets/img/banner-5/fb.png"/>
                <p>Facebook Add</p>
                </div>
                
                <div className="banner-services">
                <img src="assets/img/banner-5/insta.jpg"/>
                <p>Insta Add</p>
                </div>
                
                <div className="banner-services">
                <img src="assets/img/banner-5/whatsapp.jpg"/>
                <p>Bulk whatsapp</p>
                </div>

                <div className="banner-services">
                <img src="assets/img/banner-5/Bulk SMS.png"/>
                <p>Bulk SMS</p>
                </div>
                
                <div className="banner-services">
                <img src="assets/img/banner-5/youtube.png"/>
                <p>Youtube Ads</p>
                </div>
                
                <div className="banner-services">
                <img src="assets/img/banner-5/youtube.png"/>
                <p>Youtube Ads</p>
                </div>
                
                <div className="banner-services">
                <img src="assets/img/banner-5/youtube.png"/>
                <p>Youtube Ads</p>
                </div>
                
                <div className="banner-services">
                <img src="assets/img/banner-5/youtube.png"/>
                <p>Youtube Adds</p>
                </div>


                </div>
                {/* <p
                  className='content pe-xl-4'
                  data-aos='fade-right'
                  data-aos-delay='350'
                  data-aos-duration='1500'
                >
                  And In Order To Make A Business, Brand Advertising And
                  Marketing Plays An Important Role. Similarly, In
                </p> */}
                <a
                  className='btn btn-border-base-2 '
                  data-aos='fade-right'
                  data-aos-delay='450'
                  data-aos-duration='1500'
                  href='/about'
                >
                  Call Now 
                </a>
                <a
                  className='btn btn-border-base-2'
                  data-aos='fade-right'
                  data-aos-delay='450'
                  data-aos-duration='1500'
                  href='/about'
                >
                  whatsapp
                </a>
                <div className="mt-5 text-white">
                    <p className="text-white">Free Consultation: +91-7024881776</p>
                    <p className="text-white">Support: +91-8839281776</p>
                  </div>
                {/* <div
                  className='d-inline-block align-self-center '
                  data-aos='fade-right'
                  data-aos-delay='350'
                  data-aos-duration='1500'
                >
                  <a
                    href='javascript:void(0)'
                    onClick={() => setOpen(true)}
                    className='video-play-btn-hover'
                  >
                    <img src='assets/img/video.svg' alt='img' />{" "}
                    <h6 className='d-inline-block text-white'>how we work</h6>
                  </a>
                </div>
                <ModalVideo
                  channel='youtube'
                  autoplay
                  isOpen={isOpen}
                  videoId='XM6kTQPzzpQ'
                  onClose={() => setOpen(false)}
                /> */}
                </div>
              </div>
            <div className='col-lg-4 col-md-9 align-self-end'>
              <div className='banner-thumb-2 mt-4 mt-lg-0'>
                <div className='main-img-wrap'>
                  <img
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    src='assets/img/banner-5/4.png'
                    alt='img'
                  />
                  <img
                    className='banner-animate-img banner-animate-img-3 top_image_bounce'
                    src='assets/img/banner-5/3.png'
                    alt='img'
                  />
                  <div>
                    <img
                      className='main-img'
                      src='assets/img/banner-5/1.png'
                      alt='img'
                    /> 
                  </div>

                  {/* <ContactForm /> */}
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerFive End ==================*/}
    </>
  );
};

export default BannerFive;
