import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            {/* <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            > */}
             <div
                className='col-lg-6 about-thumb-inner p-xl-5 pt-4 '
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <img
                  className='animate-main-img'
                  src='assets/img/about/14.png'
                  alt='img'
                />
                <img
                  className='main-img m-md-4'
                  src='assets/img/about/about14.png'
                  alt='img'
                />
              </div>

              {/* <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/10.png'
                  alt='img'
                />
            </div>
              </div> */}


            
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mb-0'>
                <h6 className='sub-title'>WHY AASMO</h6>
                <h2 className='title'>
                Why <span>AASMO Digital</span> for Digital Marketing in India!
                </h2>
                {/* <p className='content mb-4'>
                  You can access SaaS applications through a web browser or
                  mobile app, as long as you have an internet connection.
                </p> */}
                <div className='row mt-5'>
                  <div className='col-md-10'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> 100% White Hat Methods Only
                      </li>
                      <li>
                        <FaCheckCircle /> Fully Managed In House SEO Services
                      </li>
                      <li>
                        <FaCheckCircle /> Dedicated Account Manager
                      </li>
                      <li>
                        <FaCheckCircle /> Latest Technologies And High Quality Service
                      </li>
                      <li>
                        <FaCheckCircle /> More Than 7 Year Of Experienced Team Members
                      </li>
                      <li>
                        <FaCheckCircle /> 100% Satisfaction Assurance
                      </li>
                    </ul>
                  </div>
                  {/* <div className='col-md-6'> 
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Mistakes To Avoid to the
                      </li>
                      <li>
                        <FaCheckCircle /> Your Startup industry stan
                      </li>
                    </ul>
                  </div>*/}
                </div>
                {/* <p className='content'>
                  SaaS providers typically invest heavily in security measures
                  to protect customer data, such as encryption and multi-factor
                  authentication. However, it is important to research and
                  choose a reputable provider and also to ensure that
                </p> */}
                <Link className='btn btn-border-base mt-5' to='/about'>
                  Get Free Consultation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
