import React from "react";
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebookF,
  FaClock,
  FaInstagram,
  FaPhoneAlt,
  FaMapMarkedAlt,
  FaLinkedin
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className='footer-area bg-black bg-cover'>
      
        <div className='container '>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/logo3.png' alt='img' />
                </div>
                <div className='details'>
                  <p>
                  At our dynamic website design company, we turn your vision into stunning online realities. Elevate your brand with our innovative designs, intuitive interfaces, and state-of-the-art solutions. Your success is our design!    
                  </p>
                  <ul className='social-media'>
                    <li>
                      <a href="https://www.facebook.com/Aasmodigital" target="_blank" rel="noopener noreferrer">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='https://wa.link/28g4c0' target="_blank" rel="noopener noreferrer">
                        <IoLogoWhatsapp />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/aasmodigitalpvtltd/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.linkedin.com/company/aasmodigitalpvtltd/mycompany/' target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Service</h4>
                <ul>
                  {/* <li>
                    <Link to='/website-design'>
                      <FaArrowRight /> Website Design
                    </Link>
                  </li> */}
                  <li>
                    <Link to='/website-development'>
                      <FaArrowRight /> Website Development
                    </Link>
                  </li>
                  <li>
                    <Link to='/digital-marketing'>
                      <FaArrowRight /> Digital marketing{" "}
                    </Link>
                  </li>
                  <li>
                    {/* <Link to='https://bulksmssender.in/'>
                      <FaArrowRight /> Bulk SMS Plan
                    </Link> */}
                    <a href='https://bulksmssender.in/' target='_blank' rel='noopener noreferrer'>
        <FaArrowRight /> Bulk SMS Plan
      </a>
                  </li>
                  <li>
                    <Link to='/social-media-management'>
                      <FaArrowRight /> Social Media Management
                    </Link>
                  </li>
                  <li>
                    <Link to='/search-engine-optimization'>
                      <FaArrowRight /> Search Engine Optimisation 
                    </Link>
                  </li>
                  <li>
                    <Link to='/whatsapp-official-api'>
                      <FaArrowRight />WhatsApp Official API
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Quick links</h4>
                <ul>
                <li>
                  <a  href="https://crm.aasmo.in/"  target='_blank' rel='noopener noreferrer'>
                  <FaArrowRight />CRM
                  </a>
                </li>
                  <li>
                    <Link to='/'>
                      <FaArrowRight /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to='/about'>
                      <FaArrowRight />About
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact'>
                      <FaArrowRight />Contact
                    </Link>
                  </li>
                  {/* <li>
                    <Link to='/blog'>
                      <FaArrowRight /> Blog
                    </Link>
                  </li> */}
                  {/* <li>
                    <a  href="https://wa.link/ijqp91" >
                      <FaArrowRight /> Live Chat
                    </a>
                  </li> */}
                  {/* <li>
                    <Link to='/contact'>
                      <FaArrowRight /> Connect with US
                    </Link>
                  </li> */}
                  <li>
                    <a  href='tel:+919109829396'>
                      <FaArrowRight /> Call Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className=' widget-recent-post'>
                <h4 className='widget-title'>Contact us</h4>       
              </div>
                 <div className='widget widget_contact'>
                  <ul className='details'>
                    <li>
                      <FaMapMarkedAlt />
                     168-169 PU4 Vijay Nagar Indore, M.P. India 452012,
                    </li>
                    <li className='mt-2'>
                      <FaClock /> Mon-Sat 10Am-6Pm
                    </li>
                    <li className='mt-3'>
                      <FaPhoneAlt /> +91 0731-4996669
                    </li>
                    <li className='mt-2'>
                      <FaEnvelope />info@aasmo.in
                    </li>                  
                  </ul>
                 </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© AASMO Digital Pvt. Ltd. 2024 | All Rights Reserved</p>
              </div>
              <div className='col-md-6 text-lg-end'>
              <a href='#'>About Us &nbsp;&nbsp; |</a>
                <Link to='/term-and-conditions'>&nbsp; Trams &amp; Condition &nbsp;&nbsp; |</Link>
                <Link to='/priacy-policy'>&nbsp; Privacy Policy &nbsp;&nbsp; |</Link>
                <Link to='/return-refund'>&nbsp; Return Refund</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
