export const bannerData = {
  title: "Digital Marketing",
  features: [
    "Global Reach",
    "Cost-Effective",
    "Targeted Advertising",
    " Measurable Results",
    "Flexibility",
    "Brand Development",
    "Engagement",
    "Accessibility",
    " Personalization",
    "ompetitive Advantage"
  ]
};

const DigitalMarketingList=[
    {
       title: "Search Engine Optimization (SEO)",
       des: "Boost your website's visibility and drive organic traffic with our comprehensive SEO strategies, including keyword research and on-page optimization.",
       img: "assets/img/service-icon/1.png",
     },
     {
       title: "Pay-Per-Click (PPC) Advertising",
       des: "Maximize your ROI with targeted PPC campaigns on platforms like Google Ads, designed to drive immediate traffic and conversions.",
       img: "assets/img/service-icon/2.png",
     },
     {
       title: "Social Media Marketing",
       des: "Engage with your audience and grow your brand presence on social media platforms through strategic content creation and community management.",
       img: "assets/img/service-icon/3.png",
     },
     {
      title: "Content Marketing",
      des: "Attract and retain customers by creating valuable and relevant content, including blog posts, articles, and infographics, to build brand authority.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Email Marketing",
      des: "Reach your audience directly with personalized email campaigns that drive engagement, nurture leads, and increase conversions.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Influencer Marketing",
      des: "Leverage the power of influencers to amplify your brand message, reach new audiences, and drive authentic engagement and conversions.",
      img: "assets/img/service-icon/3.png",
    },
    {
      title: "Conversion Rate Optimization (CRO)",
      des: "Improve your website's performance by optimizing user experience and increasing the percentage of visitors who convert into customers.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Online Reputation Management",
      des: "Protect and enhance your brand's online reputation through proactive monitoring and management of reviews, social mentions, and feedback.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Video Marketing",
      des: "Create engaging video content to capture your audience's attention, convey your message effectively, and boost your online presence.Analytics and Reporting",
      img: "assets/img/service-icon/3.png",
    },{
      title: "Analytics and Reporting",
      des: "Track and measure the effectiveness of your digital marketing campaigns with detailed analytics and reporting, providing insights for continuous improvement.",
      img: "assets/img/service-icon/1.png",
    },
    ]
export default   DigitalMarketingList;