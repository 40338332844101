import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
    console.log("hell");
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  return (
    <>
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id='body-overlay'
      ></div>
      {/* navbar start */}
      <nav className=' navbar navbar-area navbar-expand-lg'>
        <div className='container-fluid m-0  nav-container navbar-bg'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='assets/img/logo2.png'  alt='img' />
            </Link>
          </div>
          {/* <div className='nav-right-part nav-right-part-mobile'>
            <span className='search-bar-btn' onClick={searchActive}>
              <FaSearch />
            </span>
          </div> */}
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          > 
          <ul className='navbar-nav menu-open text-lg-end'>
            <li><Link to='/'>Home</Link> </li>
            <li><Link to='/about'>About Us</Link></li>
            <li className='menu-item-has-children'>
                <a href='#'>Service</a>
                <ul className='sub-menu'>          
                  <li>
                    <Link to='/website-development'>Website Development</Link>
                  </li>
                  <li>
                    <Link to='/ecommerce-website-development'>Ecommerce Website Development</Link>
                  </li>
                  <li>
                    <Link to='/digital-marketing'>Digital Marketing</Link>
                  </li>
                  <li>
                    <a href='https://bulksmssender.in/'  target='_blank' rel='noopener noreferrer'>Bulk SMS Plan</a>
                  </li>
                  <li>
                    <Link to='/social-media-management'>Social Media Management</Link>
                  </li>
                  <li>
                    <Link to='/search-engine-optimization'>Search Engine Optimization</Link>
                  </li>
                  <li>
                    <Link to='/google-ads'>Google Ads</Link>
                  </li>
                  <li>
                    <a href='https://crm.aasmo.in/'  target='_blank' rel='noopener noreferrer'>CRM</a>
                  </li>
                  <li>
                    <Link to ='/whatsapp-official-api'>
                    Whatsapp Official API
                    </Link>
                
                  </li>
                </ul>
              </li>
              {/* <li><Link to='/certificate'>Certificates</Link></li> */}
              {/* <li><Link to='/blog-details'>Blogs</Link></li> */}
              <li><Link to='/contact'>Contact Us</Link></li>
            </ul>
          </div>
          <div className='nav-right-part nav-right-part-desktop align-self-center'>
            {/* <a className='navbar-phone' href='tel:'> <span className='icon'><img src='assets/img/icon/1.png' alt='img' /></span>
              <span>Need help?</span>  <h5>(808) 555-0111</h5></a>  */}
            <a href='tel:+919109829396' type="button" class="btn btn-primary m-1 text-white">Call Now</a>
            <button type="button" class="btn btn-primary m-1">Live Chat</button>
            {/* <button className="bg-dark text-white p-1">Call Now</button><button>Chat Now</button> */}
          </div>
        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavBar;
 {/* search popup start
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id='td-search-popup'
      >
        <form action='/' className='search-form'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Search.....'
            />
          </div>
          <button type='submit' className='submit-btn'>
            <FaSearch />
          </button>
        </form>
      </div> */}
      {/* search popup end*/}
     