import React, { useRef,useState } from "react";
import emailjs from "@emailjs/browser";
import { FaPlus,FaRegUser } from "react-icons/fa";
import { toast, Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import './FormStyle.css';

const ContactMain = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
  
    from_name: "",
    form_mobile: "",
    form_city: "",
    form_message: "",
    form_email: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.from_name?.trim()) {
      newErrors.from_name = "Recipient's Name is required";
    }
    if (!formData.from_name?.trim()) {
      newErrors.from_name = "Your Name is required";
    }
    if (!formData.form_email?.trim()) {
      newErrors.form_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.form_email)) {
      newErrors.form_email = "Email is not valid";
    }
    if (!formData.form_mobile?.trim()) {
      newErrors.form_mobile = "Mobile Number is required";
    }
    if (!formData.form_city?.trim()) {
      newErrors.form_city = "City is required";
    }
    if (!formData.form_message?.trim()) {
      newErrors.form_message = "Message is required";
    }
    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Prepare the data to send via EmailJS
    const templateParams = {
      
      from_name: formData.from_name,
      form_mobile: formData.form_mobile,
      form_city: formData.form_city,
      form_message: formData.form_message,
      form_email: formData.form_email,
    };

    emailjs
      .sendForm(
        "service_nq3hz9j",
        "template_4rmdry4",
        form.current,
        "j0Ojzi2o3AkLyuaWr"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          toast.success("Form submitted successfully!");
          setFormData({
          
            from_name: "",
            form_mobile: "",
            form_city: "",
            form_message: "",
            form_email: "",
          });
          setErrors({});
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          toast.error("Failed to submit form. Please try again later.");
        }
      );
  };
  
  
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='contact-area pd-top-120 pd-bottom-120'>

      {/*========================MAP=========================*/}
      <div className='contact-g-map'>
  <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.1744797939327!2d75.88182731499795!3d22.754169085092775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd15a6d7a4a9%3A0x8ef993f073d34f14!2sPU%204%2C%20Vijay%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452012!5e0!3m2!1sen!2sin!4v1624987122696!5m2!1sen!2sin' width='600' height='450' style={{ border: 0 }} allowfullscreen='' loading='lazy'></iframe>
</div>


  {/*=======================Contact Info=========================*/}
      <div className='contact-page-list'>
        <div className='container contact-info'>
          <div className='row justify-content-center'>
           


          <div className='col-lg-4 col-md-6 ml-'>
      <div className='row media single-contact-list d-flex align-items-center justify-content-center'>
        <div className='media-left'>
          <img src='assets/img/icon/15.svg' alt='img' className='img-fluid' />
        </div>
        <div className='media-body mt-4'>
          {/* <h5>Location</h5> */}
          <h6>168-169 PU4 Vijay Nagar, Indore, Madhya Pradesh 452012</h6>
        </div>
      </div>
    </div>

            <div className='col-lg-4 col-md-6'>
              <div className='row media single-contact-list d-flex align-items-center justify-content-center'>
                <div className='media-left'>
                  <img src='assets/img/icon/14.svg' alt='img' />
                </div>
                <div className='media-body mt-4'>
                  {/* <h5>Your Email</h5> */}
                  <h6>info@aasmo.in</h6>
                  <h6>help@aasmo.in</h6>
                </div>
              </div>
            </div>
           
            <div className='col-lg-4 col-md-6'>
              <div className='row media single-contact-list d-flex align-items-center justify-content-center'>
                <div className='media-left '>
                  <img src='assets/img/icon/13.svg' alt='img' />
                </div>
                <div className='media-body mt-4'>
                  {/* <h5>Contacts us</h5> */}
                  <h6>0731 4996669</h6>
                  <h6>+91 9109829396</h6><br/>
                </div>
              </div>
            </div>
           
            </div>
        </div>
      </div>
     

      {/*==============Form==============*/}    
      <div className='contact-area pd-top-90 pd-bottom-120 '>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-5 order-lg-end '
              data-aos='fade-left'
              data-aos-delay='200'
              data-aos-duration='700'
            >
              <div className='about-thumb-inner ms-xl-5 p-xl-5 pt-4 '>
                <img
                  className='animate-main-img'
                  src='assets/img/about/18.png'
                  alt='img'
                />
                <img
                  className='main-img m-4'
                  src='assets/img/contact.jpg'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-7 order-lg-first '
              data-aos='fade-right'
              data-aos-delay='200'
              data-aos-duration='700'
            >
              <div className='section-title mb-0 mt-5 mt-lg-0 '>
                <h6 className='sub-title'>GET IN TOUCH</h6>
                <h2 className='title'>
                Get In  <span>Touch</span> 
                </h2>
                <p className='content'>
                Reach out, we're just a message away! Let's connect and turn your ideas into reality.
                </p>
                <form className="mt-4" onSubmit={sendEmail} ref={form}>
                  <div className="row">
                  <div className="col-lg-6">
                      <div className="single-input-inner">
                      <input 
                            type='text' 
                            name="form_email" 
                            value={formData.form_email}
                            onChange={handleChange}
                            placeholder="Your Email"
                          />
                          {errors.form_email && <span className="error-text">{errors.form_email}</span>}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-input-inner">
                        <input
                          type="text"
                          name="from_name"
                          value={formData.from_name}
                          onChange={handleChange}
                          placeholder="Your Name"
                        />
                        {errors.from_name && (
                          <span className="error-text">{errors.from_name}</span>
                        )}
                      </div>
                    </div>
                  
                    <div className="col-lg-6">
                      <div className="single-input-inner">
                        <input
                          type="text"
                          name="form_mobile"
                          value={formData.form_mobile}
                          onChange={handleChange}
                          placeholder="Mobile Number"
                        />
                        {errors.form_mobile && (
                          <span className="error-text">{errors.form_mobile}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-input-inner">
                        <input
                          type="text"
                          name="form_city"
                          value={formData.form_city}
                          onChange={handleChange}
                          placeholder="City"
                        />
                        {errors.form_city && (
                          <span className="error-text">{errors.form_city}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-inner">
                        <textarea
                          placeholder="Message"
                          name="form_message"
                          value={formData.form_message}
                          onChange={handleChange}
                        />
                        {errors.form_message && (
                          <span className="error-text">{errors.form_message}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-border-gray mt-0" type="submit">
                        Contact Us <FaPlus />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>   
      </div>
   </>
  );
};

export default ContactMain;
