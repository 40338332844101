import React, { useRef } from 'react';
import './WhatsappAPI.css';
import BrandAreaOne from '../components/BrandAreaOne';
import { Link } from 'react-router-dom';

const WhatsappOfficialAPI = () => {
  const pricingRef = useRef(null);

  const scrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container">
   <div className="header">
      <div className="logo">
        <img src={`${process.env.PUBLIC_URL}/assets/img/logoW.png`} alt="logo" />
        
      </div>
   
     
    </div>



      <div className="integration-section">
        <div className="text-content">
          <h1>Seamlessly Integrate <br /> WhatsApp with Your Tech Stack</h1>
          <ul>
            <li>Connect business tools and streamline workflows</li>
            <li>Integrate with ecommerce platforms, CRM, online stores</li>
            <li>Automate cross-platform workflows</li>
            <li>Manage conversations within existing systems</li>
            <li>Omnichannel integration made simple</li>
          </ul>
          <Link to='https://wa.link/wvncvm'>
          <button className="trial-button">Book Appointment </button>
          </Link>
         
        </div>
        <div className="main-image">
          <img src={`${process.env.PUBLIC_URL}/assets/img/bannerWhatsappAPI.png`} alt="Integration" />
        </div>
      </div>
      <BrandAreaOne />

      <div className="features-section">
        <h2>Features</h2>
        <p>Smart tools for efficient and personalized communication</p>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div className="feature-box" key={index}>
              <div className="feature-header">
                <img src={`${process.env.PUBLIC_URL}/assets/img/${feature.logo}`} alt={feature.title} className="feature-logo" />
                <h3 style={{ textAlign: "start" }}>{feature.title} </h3>
              </div>
              <p style={{ marginLeft: "10%", textAlign: "start" }}>{feature.description}</p>
            </div>
          ))}
        </div>
        <Link to='https://wa.link/wvncvm'>
          <button className="trial-button">Book Appointment</button>
          </Link>
         
      </div>

      <div className="pricing-section" ref={pricingRef}>
        <h2>Get great features at a price that makes sense</h2>
        <p>Affordable pricing with zero setup fees</p>
       
        <div className="pricing-grid">
          {pricingPlans.map((plan, index) => (
            <div className={`pricing-box ${plan.popular ? 'popular' : ''}`} key={index}>
              {plan.popular}
              <h3>{plan.title}</h3>
              <p className="price">{plan.price}</p>
           
              <ul>
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex}>{feature}</li>
                ))}
              </ul>
              <a href={plan.paymentLink} target="_blank" rel="noopener noreferrer">
                <button className="select-plan-btn">Select Plan</button>
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="testimonial-section ">
        <h2 style={{color:"white"}}>Over 1,000 customers</h2>
        <p  style={{color:"white"}}>What some of our 1,000+ customers across 100+ countries think of Wa chat sender.</p>
        <div className="testimonials">
          <div className="testimonial-box">
            <div className="testimonial-header">
              <img src={`${process.env.PUBLIC_URL}/assets/img/logoFooter.png`} alt="Icon 1" className="testimonial-icon" />
              <p className="testimonial-quote">“Intuitive, powerful & easy WA communication”</p>
            </div>
            <p className="testimonial-text">
              The tool is very intuitive; it is straightforward to navigate and get the functionalities out of the software.
              The documentation is pretty thorough, with relevant examples. The team inbox and functionalities are a clear winner.
              Advanced features like flows and webhooks are a boon for implementing customized flows.
            </p>
            <p className="testimonial-author">Gaurav Dwivedi<br />Business Executive</p>
          </div>
          <div className="testimonial-box">
            <div className="testimonial-header">
              <img src={`${process.env.PUBLIC_URL}/assets/img/logoFooter.png`} alt="Icon 2" className="testimonial-icon" />
              <p className="testimonial-quote">“Excellent Solution”</p>
            </div>
            <p className="testimonial-text">
              Wati has been a really useful tool for us and our business. We have several accounts, and each one of them works with no problem,
              and whenever we had any kind of issues, the support is excellent, and we immediately have a solution.
            </p>
            <p className="testimonial-author">Aman Sanodia<br />Director</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const features = [
  {
    title: 'Broadcast Communication',
    description: 'Push out your campaigns and engage with high response WhatsApp messages.',
    logo: 'broadcastCommunicationLogo.png'
  },
  {
    title: 'Chatbots',
    description: 'Create no-code chatbots to provide instant responses to common requests.',
    logo: 'chatsboatLogo.png'
  },
  {
    title: 'Multiple agents',
    description: 'Share the wa chat sender inbox across your team with support for an unlimited number of agents.',
    logo: 'multipleAgentLogo.png'
  },
  {
    title: 'Low-Code Automations',
    description: 'Simplify mass communication and personalized responses with automated tools.',
    logo: 'lowCodeAutomationsLogo2.png'
  },
  {
    title: '1-2-1 Communication',
    description: 'Tag contacts, categorize and target them in groups using personalized communications.',
    logo: 'communicationLogo.png'
  },
  {
    title: 'Easy integrations',
    description: 'Pre-built integrations let you integrate with your tech stack and configure in a few clicks.',
    logo: 'easyIntegrationsLogo.png'
  }
];

const pricingPlans = [
  {
    title: 'Basic',
    price: '₹5,999',  
    paymentLink: 'https://rzp.io/i/0IrAzvG', 
    features: [
      'Official WhatsApp API',
      'Green Tick Verification & Assistance',
      'Message Template Creation & Auditing',
      'Team Inbox',
      'Chatbot Builder',
      'Automations Builder',
      'Keywards Action',
      'Whatsapp CRM',
      'Global Webhooks & API Access',
      'E-Commerce Integrations'
    ]
  },
  {
    title: 'Addvanced',
    price: '₹10,999',
    
    paymentLink: 'https://rzp.io/i/R4FTxSpVXP',  
    popular: true,
    features: [
      'API set up help',
      'Official WhatsApp API',
      'Green Tick Verification & Assistance',
      'Message Template Creation & Auditing',
      'Team Inbox',
      'Chatbot Builder',
      'Automations Builder',
      'Keywards Action',
      'Whatsapp CRM',
      'Global Webhooks & API Access',
      'E-Commerce Integrations',
      '24 hours support'
    ]
  },

];

export default WhatsappOfficialAPI;
