import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import ContactAreaOne from "../components/ContactAreaOne";
import ContactForm from "../components/ContactForm";

const  ServiceBanner = ({bannerData}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== Banner Start ==================*/}
      <div
        className='banner-area bg-relative banner-area-2 pb-0 bg-cover'
        style={{ backgroundImage: 'url("./assets/img/banner-5/5.png")' }}
      >
        <div className='container-lg container-fluid'>
          <div className='row'>
            <div className=' col-lg-7 mt-lg-5 pt-lg-5 '>
              <div className='banner-inner pe-xl-4 pb-5'>
                <h6
                  className='text-black subtitle'
                  data-aos='fade-right'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                >
                  AASMO Digital
                </h6>
                <h2
                  className='title text-white'
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
               {bannerData.title}
                 </h2>

                 <div
                  className='center pt-4 pe-xl-4'
                  data-aos='fade-right'
                  data-aos-delay='350'
                  data-aos-duration='1500'
                >
                {/* <h6 className="text-white ms-5">1 Year Free Domain</h6>
                <h6 className="text-white ms-5">1 Year Free Hosting</h6>
                <h6 className="text-white ms-5">1 Year Free SSL Certificate</h6>
                <h6 className="text-white ms-5">Free Professional Mail 5GB Storage</h6>
                <h6 className="text-white ms-5">Mobile Friendly Website Design</h6>
                <h6 className="text-white ms-5">24x7 Support</h6> */}

                <ul className="text-white ms-lg-5   liststyle-lg">
          {bannerData.features.map((feature, index) => (
            <li  key={index} className="mb-2 h6">{feature}</li>
          ))}
        </ul>
                </div>

                <a
                  className='btn btn-border-base-2  me-sm-2 me-1'
                  data-aos='fade-right'
                  data-aos-delay='450'
                  data-aos-duration='1500'
                  href='tel:+919109829396'
                >
                  Call Now 
                </a>
                <a
                  className='btn btn-border-base-2 me-sm-2 me-1'
                  data-aos='fade-right'
                  data-aos-delay='450'
                  data-aos-duration='1500'
                  href='https://wa.link/ijqp91'
                >
                  whatsapp
                </a>
                
                <a
                    className='btn btn-border-base-2 mb-2 '
                    data-aos='fade-right'
                    data-aos-delay='450'
                    data-aos-duration='1500'
                    href='https://msng.link/o?amansanodiya786@gmail.com=sk'
                  >
                    Skype
                  </a>
                <div className=" d-none d-xl-block mt-5 text-white ms-lg-3">
                    <p className="text-white">Free Consultation: 0731 4996669</p>
                    <p className="text-white">Support: +91 9109829396</p>
                  </div>
                </div>
              </div>
           
             <div className='col-lg-5 col-md-10 align-self-start '>
              <div className='banner-thumb-2 mt-0 mt-lg-0'>
                <div className='main-img-wrap'>
                  <ContactForm />
                 
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>

      {/* ================== BannerFive End ==================*/}
    </>
  );
};

export default ServiceBanner;


