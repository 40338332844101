
export const bannerData = {
  title: "Get Professional Website @4999/-",
  features: [
    "1 Year Free Domain",
    "1 Year Free Hosting",
    "1 Year Free SSL Certificate",
    "Free Professional Mail 5GB Storage",
    "Mobile Friendly Website Design",
    "24x7 Support"
  ]
};

const WebsiteDevelopmentList=[
    {
       title: "Custom Website Design",
       des: "Tailored website designs that reflect your brand identity.User-friendly interfaces for an optimal user experience.Mobile-responsive designs to ensure seamless performance on all devices.",
       img: "assets/img/service-icon/1.png",
     },
     {
       title: " E-Commerce Solutions",
       des: "Development of robust online stores with secure payment gateways.Integration of inventory management and customer support systems.Customizable product pages and shopping carts.",
       img: "assets/img/service-icon/2.png",
     },
     {
       title: "Content Management Systems (CMS)",
       des: "Implementation of easy-to-use CMS platforms like WordPress, Joomla, and Drupal.Training and support for client teams to manage website content independently.Scalable solutions to grow with your business needs.",
       img: "assets/img/service-icon/3.png",
     },
     {
       title: "Website Maintenance and Support",
       des: "Ongoing website updates and security monitoring.Bug fixes and performance optimization.Technical support to ensure your website runs smoothly.",
       img: "assets/img/service-icon/1.png",
     },
   
     {
       title: "Web Application Development",
       des: "Development of custom web applications tailored to your business processes.Integration with existing systems and third-party APIs.Focus on performance, scalability, and user experience.",
       img: "assets/img/service-icon/3.png",
     },
     {
       title: "UI/UX Design",
       des: "Creation of intuitive and engaging user interfaces.User experience research and testing to enhance usability.Prototyping and wireframing for efficient design processes.",
       img: "assets/img/service-icon/3.png",
     },
     {
      title: "Search Engine Optimization (SEO)",
      des: "On-page and off-page SEO techniques to improve search engine rankings.Keyword research and content optimization to attract targeted traffic.Regular SEO audits and performance reports.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Responsive Design",
      des: "Ensuring your website looks great and functions well on all devices.Adaptive design techniques for improved mobile user experience. Cross-browser compatibility to reach a wider audience.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Performance Optimization",
      des: "Speed enhancement techniques for faster load times.Image and code optimization for better performance.Regular performance monitoring and adjustments.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Analytics and Reporting",
      des: "Integration of analytics tools like Google Analytics. Customizable reports to track website performance and user behavior.Data-driven insights to inform business decisions.",
      img: "assets/img/service-icon/2.png",
    },
   ];
export default WebsiteDevelopmentList;