export const bannerData = {
  title: "Get Professional Website",
  features: [
    "1 Year Free Domain",
    "1 Year Free Hosting",
    "1 Year Free SSL Certificate",
    "Free Professional Mail 5GB Storage",
    "Mobile Friendly Website Design",
    "24x7 Support"
  ]
};


const WebsiteDesignList=[
       {
          title: "Free Domain Name",
          des: "A domain is a unique and human-readable address on the internet that identifies a specific location or resource, such as a website. It consists of a chosen name and a domain extension. Domains are essential for online presence and are registered through domain registrars.",
          img: "assets/img/service-icon/1.png",
        },
        {
          title: "E-Commerce Websites",
          des: "We deliver intelligent E-Commerce solutions that streamline business processes, making them faster and more user-friendly for customers.",
          img: "assets/img/service-icon/2.png",
        },
        {
          title: "Unlimited (Bandwidth/ Space)",
          des: "Signifies hosting plans with no predetermined limits on data transfer or storage capacity. While appealing for flexibility, users should be aware of potential fair usage policies or terms of service restrictions that may apply to prevent abuse and maintain optimal server performance.",
          img: "assets/img/service-icon/3.png",
        },
        {
          title: "Free SSL Certificate",
          des: "SSL (Secure Sockets Layer) is a security protocol that enables encrypted and secure communication between a user's web browser and a website's server, safeguarding sensitive data during online transactions.",
          img: "assets/img/service-icon/2.png",
        },
        {
          title: "Professional Email",
          des: "We provide a service that enables effective reflection of your business, energizing your objectives efficiently on a larger scale.",
          img: "assets/img/service-icon/1.png",
        },
      
        {
          title: "Mobile Friendly Design",
          des: "Boost your business with our exceptional services! Creative solutions, efficient processes, and unparalleled quality to elevate your Google My Business rank.",
          img: "assets/img/service-icon/3.png",
        },
        {
          title: "Google Submission",
          des: "We provide expert management services for your Google Ads. Maximize ROI, optimize campaigns, and dominate your market effortlessly with us.",
          img: "assets/img/service-icon/3.png",
        }
      ];
      
      export default WebsiteDesignList;
      