import React from 'react'
import Banner from '../components/Banner'
import NavBar from '../components/NavBar'
import ServiceBanner from '../ServiceFolder/ServiceBanner'
import ServiceAreaGroup from '../components/ServiceAreaGroup'
import CounterAreaThree from '../components/CounterAreaThree'
import ProjectAreaOne from '../components/ProjectAreaOne'
import AboutAreaFive from '../components/AboutAreaFive'
import WebsiteDesignList from '../scripts/WebsiteDesignList'
import TestimonialOne from '../components/TestimonialOne'
import WebsiteDevelopmentList ,{bannerData} from '../scripts/WebsiteDevelopmentList'
import BrandAreaOne from '../components/BrandAreaOne'
import ParentComponent from '../scripts/TestimonialList'

const WebsiteDevelopment = () => {
  return (
    <div>
      <ServiceBanner bannerData={bannerData}/>
      <ServiceAreaGroup services={WebsiteDevelopmentList}/>
      <CounterAreaThree/>
      {/* <AboutAreaFive/>
      <ProjectAreaOne/> */}
      {/* <TestimonialOne/>   */}
      <ParentComponent/>
      <BrandAreaOne/>

    </div>
  )
}

export default WebsiteDevelopment
