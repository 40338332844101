let serviceList = [
  {
    title: "Digital Marketing",
    des: "We with our unmatched skills and years of experience are completely competent to carve out the maximum for your business in the digital world.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Graphic Designing",
    des: "Designs are not just to attract your customer's attention but it communicates your message to your target audience.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "UI/UX",
    des: "We just not design UX/UI, we design your message that can be conveyed without any encumbrance.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Website Development",
    des: "We just not code webpages for you, we create your first impression in the customer's mind.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "App Development",
    des: "We convert your idea and plan in form of a responsive, engaging and customer friendly application.",
    img: "assets/img/service-icon/1.png",
  },

  {
    title: "Branding",
    des: "Transform your brand with strategic digital solutions tailored for growth and impact.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Whatsapp API integration",
    des:"Boost customer interaction with seamless WhatsApp API integration for instant, personalized communication."
    ,img:"assets/img/service-icon/1.png"
  },
  {
    title: "Google API integration",
    des:"Leverage Google API integration to enhance your services with powerful data and seamless connectivity."
    ,img:"assets/img/service-icon/3.png"
  }
];

export default serviceList;
