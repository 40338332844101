// ParentComponent.js or wherever you use TestimonialOne
import React from 'react';
import TestimonialOne from '../components/TestimonialOne';

const testimonials = [
  {
    name: 'Shubham Mishra',
    designation: 'Marketing Coordinator',
    icon: 'assets/img/icon/25.png',
    text: 'Aasmo Digital helped my business to build brand awareness and get more leads. People here know their work well. Glad to have found a nice agency to work with.',
    rating: 5,
    thumb: 'assets/img/marketingCoordinatoricon1.png',
  },
  {
    name: 'AKHIL CHATURVEDI',
    designation: 'Sales Manager',
    icon: 'assets/img/icon/25.png',
    text: 'Aasmo Digital is the best agency that I have partnered with and have helped a lot to lift and sustain my business during 2020. I had recommend those businesses who are looking to add value for customers, Aasmo is the place to be',
    rating: 5,
    thumb: 'assets/img/salesManagericon1.png',
  },
  {
    name: 'Monika Mishra',
    designation: 'Marketing Coordinator',
    icon: 'assets/img/icon/25.png',
    text: 'Team Aasmo is young, energetic, creative and dynamic. They have helped me optimise my website and bring down custome acquistion costs from Facebook and Google. Ankit and team are doing a wonderful job for their clients. Cheers!',
    rating: 5,
    thumb: 'assets/img/marketingCoordinatoricon1.png',
  },
  {
    name: 'NAINA SHAH ',
    designation: 'Sales Manager',
    icon: 'assets/img/icon/25.png',
    text: 'Strategy | Creativity | Results - The name says it all! Aasmo Digital is a creatively driven full service digital marketing agency.It has been a good experience working with them so far',
    rating: 5,
    thumb: 'assets/img/salesManagericon1.png',
  },
  {
    name: 'ANSHU CHATURVEDI',
    designation: 'Marketing Coordinator',
    icon: 'assets/img/icon/25.png',
    text: 'Got in touch with Aasmo Digital  for my shopify website, I am really impressed with their creativity and technical know how. My project is ongoing, and i would definitely love to work more with them. Good job.',
    rating: 5,
    thumb: 'assets/img/marketingCoordinatoricon1.png',
  },
  {
    name: 'DIVYANSH SAHU',
    designation: 'Sales Manager',
    icon: 'assets/img/icon/25.png',
    text: 'We reached out to Aasmo  for help with promotion of events.They have been a fountain of knowledge, advising us on how to create campaigns that are effective, strategies using keywords and useful tools and extensions for digital marketing.They are young, energetic ,extremely professional and we look forward to working with them in the future.',
    rating: 5,
    thumb: 'assets/img/salesManagericon1.png',
  },
  {
    name: 'VIKARAM MALHOTR',
    designation: 'Sales Manager',
    icon: 'assets/img/icon/25.png',
    text: 'Aasmo Digital  has been working with me for a couple of years now. We’ve done it all, from PPC marketing to website development and SEO. They have really helped me not only to stay current in my marketing but also to remain ahead of the curve. I can’t afford not to be high in the search engine rankings, so I’m grateful that Aasmo knows exactly what to do to keep me there. Great job!!!    ',
    rating: 5,
    thumb: 'assets/img/salesManagericon1.png',
  },
  // Add more testimonials as needed
];
const ParentComponent = () => {
console.log(testimonials,"ll")

  return (
    <div>
 
      <TestimonialOne testimonials={testimonials} />  
      {console.log(testimonials,"kk")} 
    </div>
  );
};

export default ParentComponent;
