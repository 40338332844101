export const bannerData = {
  title: "Social Media Management ",
  features: [
    "Content Creation",
    "Profile Optimization",
    "Community Management",
    "Social Media Strategy",
    "Analytics and Reporting",
    "Paid Social Advertising",
    "Influencer Marketing",
    "Content Calendar Management",
    "Crisis Management"
  ]
};

const SocialMediaManagementList=[
    {
       title: "Content Creation",
       des: "We create engaging and high-quality content tailored to your brand, ensuring consistent and compelling messaging across all social media platforms.",
       img: "assets/img/service-icon/1.png",
     },
     {
       title: "Profile Optimization",
       des: "Optimize your social media profiles to enhance visibility, attract followers, and convey a professional and cohesive brand image.   ",
       img: "assets/img/service-icon/2.png",
     },
     {
       title: "Community Management",
       des: "Engage with your audience through active community management, responding to comments, messages, and mentions to build strong relationships.",
       img: "assets/img/service-icon/3.png",
     },
     {
      title: "Social Media Strategy",
      des: "Develop and implement effective social media strategies that align with your business goals, driving growth and increasing brand awareness.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Analytics and Reporting",
      des: "Track your social media performance with detailed analytics and reports, providing insights to refine your strategy and improve results.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Paid Social Advertising",
      des: "Boost your reach and engagement with targeted paid social advertising campaigns, ensuring your content reaches the right audience.",
      img: "assets/img/service-icon/3.png",
    },
    {
      title: "Influencer Marketing",
      des: "Collaborate with influencers to amplify your brand message, reach new audiences, and drive engagement through authentic partnerships.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Social Media Campaigns",
      des: "Create and execute social media campaigns that capture attention, encourage interaction, and drive traffic to your website.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Content Calendar Management",
      des: "Maintain a consistent posting schedule with our content calendar management, ensuring timely and relevant updates across all platforms.",
      img: "assets/img/service-icon/3.png",
    },
    {
      title: "Crisis Management",
      des: "Handle social media crises effectively with our proactive crisis management services, protecting your brand reputation and maintaining trust.",
      img: "assets/img/service-icon/1.png",
    },
    ]
export default SocialMediaManagementList;