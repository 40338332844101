import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { toast, Toaster } from "react-hot-toast";
import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import './FormStyle.css';

const ContactForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    from_name: '',
    form_mobile: '',
    form_email: '',
    form_city: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value, country) => {
    // Here, `value` is the formatted phone number and `country` is the selected country code.
    setFormData((prevFormData) => ({
      ...prevFormData,
      form_mobile: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.from_name?.trim()) {
      newErrors.from_name = "Full Name is required";
    }
    if (!formData.form_mobile?.trim()) {
      newErrors.form_mobile = "Mobile Number is required";
    } 
    if (!formData.form_email?.trim()) {
      newErrors.form_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.form_email)) {
      newErrors.form_email = "Email is not valid";
    }
    if (!formData.form_city?.trim()) {
      newErrors.form_city = "City is required";
    }
    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Prepare the data to send via EmailJS
    const templateParams = {
      from_name: formData.from_name,
      from_name: formData.from_name,
      form_mobile: formData.form_mobile,
      form_city: formData.form_city,
      form_email: formData.form_email,
    };

    emailjs
      .sendForm(
        "service_nq3hz9j",
        "template_4rmdry4",
        form.current,
        "j0Ojzi2o3AkLyuaWr"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          toast.success("Form submitted successfully!");
          setFormData({
            from_name: "",
            from_name: "",
            form_mobile: "",
            form_city: "",
            form_email: "",
          });
          setErrors({});
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          toast.error("Failed to submit form. Please try again later.");
        }
      );
  };

  return (
    <>
    
      <div className='contact-area contact-custom'>
        
        <div className='container-fluid p-0'>
          <div className='contact-inner'>
            <div className='row p-0'>
              <div className='col-lg-12 wow animated fadeInRight p-0'>
                <div className='section-title mb-5'>
                  <h6 className='sub-title'>GET IN TOUCH</h6>
                  <h2 className='title'>
                    Free<span> Consultation</span>
                  </h2>
                  <p className='from-content'>
                    Experience a complimentary consultation for Digital Marketing.
                  </p>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='single-input-inner style-border'>
                          <label className="text-black d-flex justify-content-start">Your Full Name</label>
                          <input 
                            type='text' 
                            name="from_name"  
                            value={formData.from_name}
                            onChange={handleChange}
                          />
                          {errors.from_name && <span className="error-text">{errors.from_name}</span>}
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='single-input-inner style-border'>
                          <label className="text-black d-flex justify-content-start">Mobile Number</label>
                          <div className="PhoneInputfield">
                          <input
                          type="number"
                          name="form_mobile"
                          value={formData.form_mobile}
                          onChange={handleChange}
                       
                        />
                        {errors.form_mobile && (
                          <span className="error-text">{errors.form_mobile}</span>
                        )}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='single-input-inner style-border inputnumber'>
                          <label className="text-black d-flex justify-content-start">Email</label>
                          <input 
                            type='text' 
                            name="form_email" 
                            value={formData.form_email}
                            onChange={handleChange}
                          />
                          {errors.form_email && <span className="error-text">{errors.form_email}</span>}
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='single-input-inner style-border'>
                          <label className="text-black d-flex justify-content-start">Your City</label>
                          <input 
                            type='text' 
                            name="form_city" 
                            value={formData.form_city}
                            onChange={handleChange}
                          />
                          {errors.form_city && <span className="error-text">{errors.form_city}</span>}
                        </div>
                      </div>
                      <div className='col-12'>
                        <button className='btn btn-black mt-0 w-100 border-radius-5' type="submit" value="send">Submit</button>
                      </div>
                      <Toaster position='top-center' reverseOrder={false} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
