// src/components/WhatsAppIcon.js

import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsappIcon.css' // Optional: For custom styling

const WhatsAppIcon = () => {
  return (
    <a
      href="https://wa.me/1234567890" // Replace with your WhatsApp number
      className="whatsapp-icon"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp size={30} />
    </a>
  );
};

export default WhatsAppIcon;
