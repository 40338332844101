import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
import HomeFour from "./pages/HomeFour";
import HomeOne from "./pages/HomeOne";
import HomeThree from "./pages/HomeThree";
import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import HomeFive from "./pages/HomeFive";
import WebsiteDesign from "./Services/WebsiteDesign";
import WebsiteDevelopment from "./Services/WebsiteDevelopment";
import EcommerceWebsiteDevelopment from "./Services/EcommerceWebsiteDevelopment";
import DigitalMarketing from "./Services/DigitalMarketing";
import BulkSmsPlan from "./Services/BulkSmsPlan";
import SocialMediaManagement from "./Services/SocialMediaManagement";
import SearchEngineOptimization from "./Services/SearchEngineOptimization";
import GoogleAds from "./Services/GoogleAds";
import Crm from "./Services/Crm";
import WhatsappMessage from "./Services/WhatsappMessage";
import NavBar from "./components/NavBar";
import FooterOne from "./components/FooterOne";
import ContactAreaOne from "./components/ContactAreaOne";
import WhatsappAPI from "./Services/WhatsappAPI";
import WhatsappIcon from "./components/WhatsappIcon";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();
  const hideNavAndFooter = location.pathname === '/whatsapp-official-api';

  return (
    <>
      {!hideNavAndFooter && <NavBar />}
      <Routes>
        <Route exact path='/' element={<HomeOne />} />
        <Route exact path='/index-2' element={<HomeTwo />} />
        <Route exact path='/index-3' element={<HomeThree />} />
        <Route exact path='/index-4' element={<HomeFour />} />
        <Route exact path='/index-5' element={<HomeFive />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/blog-details' element={<BlogDetails />} />
        <Route exact path='/case-study-details' element={<CaseStudyDetails />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/website-design' element={<WebsiteDesign />} />
        <Route exact path='/website-development' element={<WebsiteDevelopment />} />
        <Route exact path='/ecommerce-website-development' element={<EcommerceWebsiteDevelopment />} />
        <Route exact path='/digital-marketing' element={<DigitalMarketing />} />
        {/* <Route exact path='/bulk-sms-plan' element={<BulkSmsPlan />} /> */}
        {/* <Route exact path='/whatsapp-message' element={<WhatsappMessage />} /> */}
        <Route exact path='/social-media-management' element={<SocialMediaManagement />} />
        <Route exact path='/search-engine-optimization' element={<SearchEngineOptimization />} />
        <Route exact path='/google-ads' element={<GoogleAds />} />
        <Route exact path='/whatsapp-official-api' element={<WhatsappAPI />} />
        {/* <Route exact path='/crm' element={<Crm />} /> */}
      </Routes>

     
      {!hideNavAndFooter && <FooterOne />}
     
   
      {/* <ScrollToTop smooth color='#246BFD' /> */}
      <WhatsappIcon/>
     
    </>
  );
}

export default App;