import React from 'react'
import Banner from '../components/Banner'
import NavBar from '../components/NavBar'
import ServiceBanner from '../ServiceFolder/ServiceBanner'
import TestimonialOne from '../components/TestimonialOne'
import ServiceAreaGroup from '../components/ServiceAreaGroup'
import CounterAreaThree from '../components/CounterAreaThree'
import CounterAreaFour from '../components/CounterAreaFour'
// import Portfolio from '../ServiceFolder/Portfolio';
import ProjectAreaOne from '../components/ProjectAreaOne'
import AboutAreaFive from '../components/AboutAreaFive'
import GoogleAdsList,{bannerData} from '../scripts/GoogleAdsList'
import BrandAreaOne from '../components/BrandAreaOne'
import ParentComponent from '../scripts/TestimonialList'

const GoogleAds = () => {
  return (
    <div>
      <ServiceBanner bannerData={bannerData}/>
      <ServiceAreaGroup services={GoogleAdsList}/>
      <CounterAreaThree/>
      {/* <AboutAreaFive/>
      <ProjectAreaOne/> */}
      {/* <TestimonialOne/>  */}
      <ParentComponent/>
      <BrandAreaOne/>

    </div>
  )
}

export default GoogleAds
