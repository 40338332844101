// TestimonialItem.js
import React from "react";
import { FaStar } from "react-icons/fa";

const TestimonialData = ({ name, designation, icon, text, rating, thumb }) => {

  return (
    <div className='item'>
      <div className='single-testimonial-inner style-1 text-center'>
        <h5>{name}</h5>
        <p className='designation mb-3'>{designation || ""}</p>
        <div className='icon mb-2'>0
          <img src={icon} alt='icon' />
        </div>
        <p>{text}</p>
        <div className='ratting-inner mt-4'>
          {Array(rating)
            .fill()
            .map((_, i) => (
              <FaStar key={i} />
            ))}
        </div>
        <div className='thumb'>
          <img src={thumb} alt='testimonial' />
        </div>
      </div>
    </div>
  );
};

export default TestimonialData;
