export const bannerData = {
  title: "Get Ecommerce Website @ 12,999/-",
  features: [
    "1 Year Free Domain",
    "1 Year Free Hosting",
    "1 Year Free SSL Certificate",
    "Unlimited Categories",
    "Unlimited Sub Categories",
    "Unlimited Coupon Codes",
    "Admin Panel",
    "User Panel",
    "Manage Orders",
    "Print Invoices",
    "Free Professional Mail 5GB Storage",
    "Mobile Friendly Website Design",
    "24x7 Support"
  ]
};

const EcommerceWebsiteDevelopmentList=[
    {
       title: "Custom E-Commerce Design",
       des: "We design custom e-commerce websites that reflect your brand identity, ensuring an engaging and user-friendly shopping experience.Secure Payment Integration",
       img: "assets/img/service-icon/1.png",
     },
     {
       title: "Secure Payment Integration",
       des: "Integrate secure payment gateways to provide your customers with safe and convenient payment options, enhancing trust and satisfaction.",
       img: "assets/img/service-icon/2.png",
     },
     {
       title: "Product Management",
       des: "Easily manage your products with our intuitive product management system, allowing you to add, edit, and organize your inventory effortlessly.",
       img: "assets/img/service-icon/3.png",
     },
     {
      title: "Shopping Cart and Checkout",
      des: "Optimize your shopping cart and checkout process to minimize cart abandonment and ensure a smooth, hassle-free purchase experience.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Mobile-Responsive Design",
      des: "Ensure your e-commerce website is fully responsive, providing an excellent shopping experience on all devices, including smartphones and tablets.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "SEO Optimization",
      des: "Enhance your e-commerce site's visibility with SEO optimization, driving organic traffic and improving search engine rankings. ",
      img: "assets/img/service-icon/3.png",
    },
    {
      title: "Analytics and Reporting",
      des: "Track your sales performance with detailed analytics and reporting, providing insights to help you make data-driven business decisions.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Customer Account Management",
      des: "Offer customer account features, including order history, wish lists, and personalized recommendations, to enhance user experience and loyalty.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Multi-Language and Multi-Currency Support",
      des: "Expand your reach with multi-language and multi-currency support, catering to international customers and boosting global sales.",
      img: "assets/img/service-icon/3.png",
    },{
      title: "Third-Party Integrations",
      des: "Integrate your e-commerce site with third-party tools and platforms, such as inventory management systems, CRMs, and marketing tools, for seamless operations.",
      img: "assets/img/service-icon/1.png",
    },
    ]
  
export default EcommerceWebsiteDevelopmentList;