// import React from "react";
// import CountUp from "react-countup";
// import TrackVisibility from "react-on-screen";

// const CounterAreaOne = () => {
//   return (
//     <>
//       {/* counter area start */}
//       <div
//         className='container-md  counter-area bg-relative bg-cover  pd-top-110 pd-bottom-100 h '
//         style={{ backgroundColor: "#104CBA" }}

//         // style={{ backgroundImage: 'url("./assets/img/bg/5.png")' }} #104CBA
//       >
//         <div className=' pd-bottom-90** '>
//           <div className='row justify-content-center m-auto'>
//             <div
//               className='col-lg-3 col-sm-6'
//               data-aos='fade-up'
//               data-aos-delay='100'
//               data-aos-duration='1500'
//             >
//               <div className='single-counter-inner '
//                 >
//                 <div className='thumb'>
//                   <img src='assets/img/icon/5.svg' alt='img' />
//                 </div>
//                 <h2 className='text-white mt-4 mb-2 '>
//                   <TrackVisibility once>
//                     {({ isVisible }) =>
//                       isVisible && (
//                         <span className='counter'>
//                           <CountUp duration={1} delay={0} start={0} end={1800} />{" "}
//                           +
//                         </span>
//                       )
//                     }
//                   </TrackVisibility>
//                 </h2>
//                 <p className='text-white'>HAPPY CLIENTS</p>
//               </div>
//             </div>
//             <div
//               className='col-lg-3 col-sm-6'
//               data-aos='fade-up'
//               data-aos-delay='150'
//               data-aos-duration='1500'
//             >
//               <div className='single-counter-inner'>
//                 <div className='thumb'>
//                   <img src='assets/img/icon/6.svg' alt='img' />
//                 </div>
//                 <h2 className='text-white mt-4 mb-2'>
//                   <TrackVisibility once>
//                     {({ isVisible }) =>
//                       isVisible && (
//                         <span className='counter'>
//                           <CountUp duration={1} delay={0} start={0} end={500} />{" "}
//                           +
//                         </span>
//                       )
//                     }
//                   </TrackVisibility>
//                 </h2>
//                 <p className='text-white'>FINISHED PROJECTS</p>
//               </div>
//             </div>
//             <div
//               className='col-lg-3 col-sm-6'
//               data-aos='fade-up'
//               data-aos-delay='200'
//               data-aos-duration='1500'
//             >
//               <div className='single-counter-inner'>
//                 <div className='thumb'>
//                   <img src='assets/img/icon/7.svg' alt='img' />
//                 </div>
//                 <h2 className='text-white mt-4 mb-2'>
//                   <TrackVisibility once>
//                     {({ isVisible }) =>
//                       isVisible && (
//                         <span className='counter'>
//                           <CountUp duration={3} delay={0} start={0} end={250} />{" "}
//                           +
//                         </span>
//                       )
//                     }
//                   </TrackVisibility>
//                 </h2>
//                 <p className='text-white'>SKILLED EXPERTS</p>
//               </div>
//             </div>
//             <div
//               className='col-lg-3 col-sm-6 '
//               data-aos='fade-up'
//               data-aos-delay='250'
//               data-aos-duration='1500'
//             >
//               <div className='single-counter-inner' >
//                 <div className='thumb'>
//                   <img src='assets/img/icon/8.svg' alt='img' />
//                 </div>
//                 <h2 className='text-white mt-4 mb-2'>
//                   <TrackVisibility once>
//                     {({ isVisible }) =>
//                       isVisible && (
//                         <span className='counter'>
//                           <CountUp duration={3} delay={0} start={0} end={1100} />{" "}
//                           +
//                         </span>
//                       )
//                     }
//                   </TrackVisibility>
//                 </h2>
//                 <p className='text-white'>MEDIA POSTS</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* counter area end */}
//     </>
//   );
// };

// export default CounterAreaOne;

import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const CounterAreaOne = () => {
  return (
    <>
      {/* counter area start */}
      <div
        className='container-fluid counter-area bg-relative bg-cover pd-top-100 pd-bottom-80'
        style={{ backgroundColor: "#104CBA" }}
      >
        <div className='pd-bottom-0'>

        <div className='section-title text-center '>
            <h6 className='sub-title'>OUR SERVICES</h6>
            <h2 className='title text-white'>
              Your Partner In Digital Success
            </h2>
          </div>

          <div className='row justify-content-center m-auto'>
            <div
              className='col-lg-3 col-sm-6 mb-4'
              data-aos='fade-up'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='single-counter-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/icon/5.svg' alt='img' />
                </div>
                <h2 className='text-white mt-4 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp duration={1} delay={0} start={0} end={1800} />{" "}
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>HAPPY CLIENTS</p>
              </div>
            </div>
            <div
              className='col-lg-3 col-sm-6 mb-4'
              data-aos='fade-up'
              data-aos-delay='150'
              data-aos-duration='1500'
            >
              <div className='single-counter-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/icon/6.svg' alt='img' />
                </div>
                <h2 className='text-white mt-4 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp duration={1} delay={0} start={0} end={500} />{" "}
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>FINISHED PROJECTS</p>
              </div>
            </div>
            <div
              className='col-lg-3 col-sm-6 mb-4'
              data-aos='fade-up'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='single-counter-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/icon/7.svg' alt='img' />
                </div>
                <h2 className='text-white mt-4 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp duration={3} delay={0} start={0} end={250} />{" "}
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>SKILLED EXPERTS</p>
              </div>
            </div>
            <div
              className='col-lg-3 col-sm-6 mb-4'
              data-aos='fade-up'
              data-aos-delay='250'
              data-aos-duration='1500'
            >
              <div className='single-counter-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/icon/8.svg' alt='img' />
                </div>
                <h2 className='text-white mt-4 mb-2'>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp duration={3} delay={0} start={0} end={1100} />{" "}
                          +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className='text-white'>MEDIA POSTS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter area end */}
      <style jsx>{`
        @media (max-width: 767px) {
          .counter-area {
            padding-top: 60px;
            padding-bottom: 60px;
          }
          .single-counter-inner {
            margin-bottom: 30px;
          }
          .text-center {
            text-align: center;
          }
        }
        @media (min-width: 768px) and (max-width: 991px) {
          .counter-area {
            padding-top: 80px;
            padding-bottom: 80px;
          }
          .single-counter-inner {
            margin-bottom: 40px;
          }
        }
      `}</style>
    </>
  );
};

export default CounterAreaOne;
