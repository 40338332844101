import React from "react";
import Marquee from "react-fast-marquee";
const BrandAreaOne = () => {
  return (
    <div className='about-area bg-gray pt-5 pb-5'>
    <div className="container pb-5">
    <div className='section-title text-center'>
            <h6 className='sub-title'>DISCOVER OUR COMPANY</h6>
            <h2 className='title'>
             Some of <span>Our Clients</span>
            </h2>
          </div>
     
    </div>

      <div className='container'>
        <div className='client-slider'>
          <Marquee gradient={false}>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo1.jpg' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo2.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo3.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo4.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo5.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo6.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo8.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo7.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/about/clientlogo/logo9.png' alt='img' />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BrandAreaOne;

// import React from "react";
// import Marquee from "react-fast-marquee";
// const BrandAreaOne = () => {
//   return (
//     <div className='about-area bg-gray pt-5 pb-5'>
//       <div className='container'>
//         <div className='client-slider'>
//           <Marquee gradient={false}>
//             <div className='thumb'>
//               <img src='assets/img/client/7.svg' alt='img' />
//             </div>
//             <div className='thumb'>
//               <img src='assets/img/client/8.svg' alt='img' />
//             </div>
//             <div className='thumb'>
//               <img src='assets/img/client/9.svg' alt='img' />
//             </div>
//             <div className='thumb'>
//               <img src='assets/img/client/11.svg' alt='img' />
//             </div>
//             <div className='thumb'>
//               <img src='assets/img/client/8.svg' alt='img' />
//             </div>
//             <div className='thumb'>
//               <img src='assets/img/client/9.svg' alt='img' />
//             </div>
//           </Marquee>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BrandAreaOne;
