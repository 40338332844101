export const bannerData = {
  title: "Search Engine Optimization",
  features: [
    " Increased Visibility",
    "Organic Traffic Growth",
    " Credibility and Trust",
    "Better User Experience",
    "Cost-Effectiveness",
    "Mobile Optimization",
    "Local Search Optimization",
    "Competitive Advantage",
    "Content Relevance",
    "Measurable Results",
    "Global Reach",
    " Adaptability to Algorithm Changes",
    "Long-Term Sustainability"
  ]
};


const SearchEngineOptimizationList=[
    {
       title: "On-Page SEO",
       des: "Optimize your website’s content and structure with targeted keywords, meta tags, and high-quality content to improve search engine rankings.",
       img: "assets/img/service-icon/1.png",
     },
     {
       title: "Off-Page SEO",
       des: "Enhance your site’s authority through strategic link building, social media engagement, and influencer outreach to boost organic traffic.",
       img: "assets/img/service-icon/2.png",
     },
     {
       title: "Technical SEO",
       des: "Improve your website’s technical aspects, such as site speed, mobile-friendliness, and crawlability, ensuring search engines can index your site effectively.",
       img: "assets/img/service-icon/3.png",
     },
     {
      title: "Local SEO",
      des: "Increase your visibility in local search results with localized content, Google My Business optimization, and local citation building.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Keyword Research",
      des: "Conduct in-depth keyword research to identify high-impact keywords and phrases that align with your business goals and target audience.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Content Optimization",
      des: "Enhance your website’s content for better search engine visibility, user engagement, and conversion rates through strategic content creation and optimization",
      img: "assets/img/service-icon/3.png",
    },
    {
      title: "SEO Audits",
      des: "Perform comprehensive SEO audits to identify and fix issues affecting your site’s performance, ensuring it meets best practices.",
      img: "assets/img/service-icon/1.png",
    },
    {
      title: "Competitor Analysis",
      des: "Analyze your competitors’ SEO strategies to uncover opportunities and refine your approach for better results.Performance Tracking and Reporting",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "Performance Tracking and Reporting",
      des: "Monitor your SEO performance with detailed tracking and reporting, providing insights to adjust strategies and achieve better outcomes.Mobile SEO",
      img: "assets/img/service-icon/3.png",
    },{
      title: "Mobile SEO",
      des: "Optimize your website for mobile search to ensure it performs well on all devices, improving user experience and search engine rankings.",
      img: "assets/img/service-icon/1.png",
    },
    ]
  
export default SearchEngineOptimizationList;