import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import ContactAreaOne from "./ContactAreaOne";
import ContactForm from "./ContactForm";
import './Banner11.css'; // Import the CSS file for additional styles

const Banner1 = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== Banner Start ==================*/}
      <div
        className='banner-area bg-relative banner-area-2 pb-0 bg-cover'
        style={{ backgroundImage: 'url("./assets/img/banner-5/5.png")' }}
      >
        <div className='container-lg container-fluid'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='banner-inner pe-xl-4 pb-5'>
                <h6
                  className='text-black subtitle'
                  data-aos='fade-right'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                >
                  AASMO Digital
                </h6>
                <h2
                  className='title text-white'
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
                  The Ultimate One-Step Solution for All Your Marketing Needs
                </h2>

                <div className="pt-4">
                  <div
                    className='center pe-xl-4'
                    data-aos='fade-right'
                    data-aos-delay='350'
                    data-aos-duration='1500'
                  >

<div className="row justify-content-lg-start justify-content-center">
  {[
    { img: "Youtube1.png", text: "Youtube Ads" },
    { img: "GoogleAdd.png", text: "Google Ads" },
    { img: "Untitled design (3).png", text: "Meta Ads" },
    { img: "Seo.png", text: "SEO" },
    { img: "bulksms.png", text: "Bulk SMS" },
    { img: "whatsapp.png", text: "Bulk WhatsApp" },
    { img: "google.png", text: "Google Business" },
    { img: "app-store.png", text: "App Development" },
    { img: "website.png", text: "Web Development" },
    { img: "uiux.png", text: "UI/UX", special: true } // Add a special key to the last item
  ].map((service, index) => (
    <div 
      key={index} 
      className={`col-4 col-sm-2 m-sm-1 mb-0 banner-service ${service.special ? 'last-service' : ''}`}
    >
      <img src={`assets/img/banner-5/${service.img}`} alt={service.text} className="img-fluid" />
      <p className="banner-service-content">{service.text}</p>
    </div>
  ))}
</div>
                  </div>
                </div>

                <div className="btn-container ">
                  <a
                    className='btn btn-border-base-2 me-sm-2 me-1 mb-2'
                    data-aos='fade-right'
                    data-aos-delay='450'
                    data-aos-duration='1500'
                    href='tel:+919109829396'
                  >
                    Call Now 
                  </a>
                  <a
                    className='btn btn-border-base-2 me-sm-2 me-1 mb-2 '
                    data-aos='fade-right'
                    data-aos-delay='450'
                    data-aos-duration='1500'
                    href='https://wa.link/ijqp91'
                  >
                    WhatsApp
                  </a>
                  
                  <a
                    className='btn btn-border-base-2 mb-2 '
                    data-aos='fade-right'
                    data-aos-delay='450'
                    data-aos-duration='1500'
                    href='https://msng.link/o?amansanodiya786@gmail.com=sk'
                  >
                    Skype
                  </a>
                </div>
                
                <div className="d-none d-xl-block mt-5 text-white ms-lg-3">
                    <p className="text-white">Free Consultation: 0731 4996669</p>
                    <p className="text-white">Support: +91 9109829396</p>
                  </div>

              </div>
            </div>
           
            <div className='col-lg-5 col-md-10 align-self-start  p-0 m-0 '>
              <div className='banner-thumb-2 mt-0 mt-lg-0 '>
                <div className='main-img-wrap'>
                  <ContactForm />
               
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>

      {/* ================== BannerFive End ==================*/}
    </>
  );
};

export default Banner1;
