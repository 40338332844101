import React from 'react'
let logo1 ,logo2,logo3,logo4,logo5,logo6,logo7,logo8,logo9,logo10;
let logo11 ,logo12,logo13,logo14,logo15,logo16,logo17,logo18,logo19;
const logos = [
    
    logo1 = "assets/img/techstack/JavaScriptLogo.png",
    logo2="assets/img/techstack/ReactLogo.png",
    logo3="assets/img/techstack/AwsLogo.png",
    logo4="assets/img/techstack/VueLogo.png",
    ,logo5="assets/img/techstack/bootstraplogo.png"
    ,logo6="assets/img/techstack/jQueryLogo.png"
    ,logo7="assets/img/techstack/JSP.png"
    ,logo8="assets/img/techstack/JSFlog.png"
    ,logo9="assets/img/techstack/AngularLogo.png"
    ,logo10="assets/img/techstack/NodeJsLogo.png"
    ,logo11="assets/img/techstack/MSSQLLogo.png"
    ,logo12="assets/img/techstack/MSSQLServerLogo.png"
    ,logo13="assets/img/techstack/PostgreSQLLogo.png"
    ,logo14="assets/img/techstack/MongoDBLogo.png"
    ,logo15="assets/img/techstack/GitLogo.png"
    ,logo16="assets/img/techstack/GithubLogo.png"
    ,logo17="assets/img/techstack/GitlabLogo.png"
    ,logo18="assets/img/techstack/postmanlogo.png"
    // Add the rest of your logos here
  ];
const TechStack = () => {
    const rows = [];

    for (let i = 0; i < logos.length; i += 7) {
      const row = logos.slice(i, i + 7);
      rows.push(row);
    } 

  return (
    <div className='contianer-fluid pd-top-100'>
       <div className='section-title text-center'>
            <h6 className='sub-title'> TechStack</h6>
            <h2 className='title'>
              Our <span>TechStack</span> 
            </h2>
          </div>

          {/* <div className="container mt-4">
      {rows.map((row, rowIndex) => (
        <div className="row mb-4" key={rowIndex}>
          {row.map((logo, logoIndex) => (
            <div className="col-6 col-md-4 col-lg-2" key={logoIndex}>
              <img src={logo} className="img-fluid" alt={`logo-${logoIndex}`} />
            </div>
          ))}
        </div>
      ))}
    </div> */}

    <div className="container mt-4">
      <div className="d-flex flex-wrap justify-content-center">
        {logos.map((logo, index) => (
          <div className="col-6 col-md-4 col-lg-2 d-flex justify-content-center mb-4" key={index}>
            <img src={logo} className="img-fluid" alt={`logo-${index}`} />
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

export default TechStack
