import React from "react";
import BlogAreaOne from "../components/BlogAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import AboutAreaTwo from "../components/AboutAreaTwo";
import ServiceAreaOne from "../components/ServiceAreaOne";
import Banner1 from "../components/Banner1";
import TechStack from "../components/TechStack";
import ImagesBlock from "../components/ImagesBlock";
import ClientLogo from "../components/ClientLogo";
import BrandAreaOne from "../components/BrandAreaOne";
import ParentComponent from "../scripts/TestimonialList";

const HomeOne = () => {
  return (
    <>
      <Banner1/> 
      <ServiceAreaOne />
      <AboutAreaTwo />
      {/* <ImagesBlock/> */}
      <CounterAreaOne />      
      <TechStack/> 
      {/* <BlogAreaOne /> */}
      {/* <ClientLogo/> */}
      <BrandAreaOne/>
      <ParentComponent/>

    </>
  );
};

export default HomeOne;
